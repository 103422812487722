import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Gemini Career
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/gemini"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Gemini</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Gemini Career</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Gemini Career</h1>
                <h2 className="text-sm md:text-base ml-4">May 21 - Jun 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-love");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-nature");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-man");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-traits");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-facts");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              <p className="font-bold text-orange-500 inline-block mr-2">Career Mantra:</p>I make my own reality<br/>
              <p className="font-bold text-orange-500 inline-block mr-2">Lessons to be given:</p>multi-tasking, creativity, communication and negotiation skills, versatility
              <br></br>
              <p className="font-bold text-orange-500 inline-block mr-2">Things you need to learn:</p> patience, focus, determination, consistency, timely decision making <br></br>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Gemini and Career</p><br/>
              Gemini natives are born with a brilliant mind, friendly vibe, and out-of-the-box creativity, hence are in demand on the job front. You never face trouble in getting hired as you are a good conversationalist and outstandingly perform in an interview. You do best when you control your career destiny. <br></br>
              <br></br>
              Being twins, you need a career or job that allows you to do multitasking and working in different areas. Many of you are entrepreneurs because clocking into a regular job will wear you out mentally. You love diving into multiple projects at a time as all of them interest you.<br></br>
              <br></br>
              For you, the office is a place to meet new people, gel with them, and socialize even more. You love to talk with colleagues, employees, and superiors. You put your opinions and ideas upfront without any hesitation. You are extremely excited by any kind of creative, strategic or brainstorming activity at the workplace. You simply hate the strict 10 to 7 work scenario and routine tasks. You are extremely moody and will work only when you feel like doing it. You enjoy coffee time with colleagues, gossiping with your close mates on phone or playing puzzles and solving mysteries during work hours. At the same time, you will gear up yourself and finish your task sitting late in the office when you feel like working. <br></br>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">How do Gemini woks?</p><br/>
              You are always excited to take up a new project and tasks as you love to do new things. You always thrive to perform the task that allows making the most of your learning, knowledge, and resourcefulness. You may not be able to finish the tasks and projects in hand due to your keenness and excitement for handling new things. You get bored easily and are likely to get frustrated while working on the same project, performing a monotonous task, and working in a quiet and sterile office. <br></br>
              <br></br>
              Hence, you always have an urge for change and may try to finish in-hand projects and monotonous tasks as early as possible. You need to develop consistency and patience in the workplace as changing your job often will not give you success.
              You will perform outstandingly in the career that is challenging, requires lots of thinking, problem-solving, and provides mental stimulation. <br></br>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Best Career Options for Gemini</p><br/>
              Gemini will usually make their career in fields involving communication, travel and intellectual expressions such as journalism, travel and tourism, media, interpretation, negotiation, linguistics and so on. You are a skillful and tactful negotiator and usually excel in politics and business.
              You are ruled by Mercury so an intellectually challenging career is the best for you. Careers as an Accountant, software developer, interpreter, public relations, project manager, scientist, teacher, engineer, communication specialist, and media analyst are good fits for you. Your charming personality and intellectual mind will be an added advantage in these career paths. Any area of occupation that needs too much of talking and communication like a salesperson, attorney, or preacher will also suit you. <br></br>
              <br></br>
              If anyone can find a way to create more money, it is a Gemini. You always have an ace up your sleeve and can always make more money in a short time. You have so many money-making schemes up in your mind and are never out of good ideas but you never plan to go for them. You will always prefer to work hard for earning money.
              You don’t watch your expenditure and spend too much on luxuries. You may find difficulty balancing your spending pattern on necessities and luxuries. You never have savings plans for yourselves but seem to be financially sound. This is because you are flexible enough to handle tough and tight situations. <br></br>
              <br></br>
              In our daily free horoscope, you’ll get all your answers related to your career, your finance, your love relationships, and your health. If you want to ask more about yourself or your loved ones, you can definitely connect to our best astrologers, they are 24/7 available to you and solve all your queries. <br></br>
              <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
